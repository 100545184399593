<template>
  <div id="CompanyList">
    <div class="posFix" ref="posFix">
      <div class="titleTop" >
        <!-- <div @click="changePath('/FindOut')">问答</div> -->
        <div @click="changePath('/Connections')">人脉圈</div>
        <div class="active">公司库</div>
        <div @click="changePath('/ActivityLine?activeName=c')">线下活动</div>
        <div @click="changePath('/ActivityLine?activeName=d')">保观集市</div>
      </div>
      <van-search
        v-model="search"
        shape="round"
        background="#23252E"
        placeholder="请输入搜索关键词"
        @input = focusChange
        style="padding:0.15rem"
      />
      <div class="hotMain">
        <div class="forth">
          <div>
            <span :class="{'active':showArea}" @click="showArea = !showArea;showindustryField=false;showFeature=false;showOther=false;">国内区域
              <van-icon v-if="showArea" name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(90deg);" />
              <van-icon v-else name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(-90deg);" />
            </span>
            <span :class="{'active':showindustryField}" @click="showArea = false;showindustryField=!showindustryField;showFeature=false;showOther=false;">行业领域
              <van-icon v-if="showindustryField" name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(90deg);" />
              <van-icon v-else name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(-90deg);" />
            </span>
            <!-- <span :class="{'active':showFeature}" @click="showArea = false;showindustryField=false;showFeature=!showFeature;showOther=false;">成立时间
              <van-icon v-if="showFeature" name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(90deg);" />
              <van-icon v-else name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(-90deg);" />
            </span> -->
            <span :class="{'active':showOther}" @click="showArea = false;showindustryField=false;showFeature=false;showOther=!showOther;">其他筛选
              <van-icon v-if="showOther" name="play" style="margin:0rem 0.05rem 0 0rem;transform: rotate(90deg);" />
              <van-icon v-else name="play" style="margin:0.rem 0.05rem 0 0rem;transform: rotate(-90deg);" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="blackFix" ref="blackFix"></div>

    <!-- 公司列表 -->
    <div class="allList" ref="topMost">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="list" @click="toDetail(i.id)" v-for="(i,item) in getDocList" :key="item">
          <img :src="Global.changeImgCom(i.thumb)"  />
          <div>
            <p class="first">{{i.title}} {{i.area}} {{i.label}}</p>
            <p class="second">{{i.location}}</p>
            <!-- <p class="forth">{{i.area}}</p> -->
            <!-- <p class="third"><span v-for="(j,items) in i.keywords.split(' ')" :key="items">{{j}}</span></p> -->
          </div>
        </div>
      </van-list>
    </div>


    <!-- 国内区域 -->
    <van-popup v-model="showArea"  position="top" @close="closeAre"> 
      <!-- <van-area  :area-list="areaList" /> -->
      <div class="topPop">
        <div class="titleTab">
          <div :class="{'active':area1 == ''}" @click="area1 = ''">不限</div>
          <div :class="{'active':area1 == '北京市'}" @click="area1 = '北京市'">北京市</div>
          <div :class="{'active':area1 == '上海市'}" @click="area1 = '上海市'">上海市</div>
          <div :class="{'active':area1 == '深圳市'}" @click="area1 = '深圳市'">深圳市</div>
          <div :class="{'active':area1 == '广州市'}" @click="area1 = '广州市'">广州市</div>
          <div :class="{'active':area1 == '杭州市'}" @click="area1 = '杭州市'">杭州市</div>
          <div :class="{'active':area1 == '成都市'}" @click="area1 = '成都市'">成都市</div>
          <div :class="{'active':area1 == '南京市'}" @click="area1 = '南京市'">南京市</div>
          <div :class="{'active':area1 == '武汉市'}" @click="area1 = '武汉市'">武汉市</div>
          <div :class="{'active':area1 == '苏州省'}" @click="area1 = '苏州省'">苏州省</div>
          <div :class="{'active':area1 == '重庆市'}" @click="area1 = '重庆市'">重庆市</div>
          <div :class="{'active':area1 == '北京市,上海市,深圳市,广州市,杭州市,成都市,南京市,武汉市,苏州省,重庆市'}" @click="area1 = '北京市,上海市,深圳市,广州市,杭州市,成都市,南京市,武汉市,苏州省,重庆市'">其他</div>
        </div>
        <div class="anscontain"><span @click="area1 = ''">重置</span><span @click="checkCateIdArea">完成</span></div>
      </div>
    </van-popup>
    <!-- 行业领域 -->
    <van-popup v-model="showindustryField" position="top" @close="closeInd">
      <div class="topPop">
        <div class="titleTab">
            <!-- <div :class="{'active':financing == ' '}" @click="financing = ' '">不限</div>
            <div :class="{'active':financing == '不需要融资'}" @click="financing = '不需要融资'">不需要融资</div>
            <div :class="{'active':financing == '种子轮'}" @click="financing = '种子轮'">种子轮</div>
            <div :class="{'active':financing == '天使轮'}" @click="financing = '天使轮'">天使轮</div>
            <div :class="{'active':financing == 'A轮'}" @click="financing = 'A轮'">A轮</div>
            <div :class="{'active':financing == 'B轮'}" @click="financing = 'B轮'">B轮</div>
            <div :class="{'active':financing == 'C轮'}" @click="financing = 'C轮'">C轮</div>
            <div :class="{'active':financing == 'D轮'}" @click="financing = 'D轮'">D轮</div>
            <div :class="{'active':financing == 'E轮'}" @click="financing = 'E轮'">E轮</div>
            <div :class="{'active':financing == 'IPO'}" @click="financing = 'IPO'">IPO</div>
            <div :class="{'active':financing == '上市'}" @click="financing = '上市'">上市</div>
            <div :class="{'active':financing == 'A轮,B轮'}" @click="financing = 'A轮,B轮'">其他轮次</div> -->
          <div :class="{'active':industryField1 == ''}" @click="industryField1 = ''">不限</div>
          <div :class="{'active':industryField1 == '保险公司'}" @click="industryField1 = '保险公司'">保险公司</div>
          <div :class="{'active':industryField1 == '保险科技'}" @click="industryField1 = '保险科技'">保险科技</div>
          <div :class="{'active':industryField1 == '大数据'}" @click="industryField1 = '大数据'">大数据</div>
          <div :class="{'active':industryField1 == '人工智能'}" @click="industryField1 = '人工智能'">人工智能</div>
          <div :class="{'active':industryField1 == 'SAAS'}" @click="industryField1 = 'SAAS'">SAAS</div>
          <div :class="{'active':industryField1 == '软件开发'}" @click="industryField1 = '软件开发'">软件开发</div>
          <div :class="{'active':industryField1 == '车险'}" @click="industryField1 = '车险'">车险</div>
          <div :class="{'active':industryField1 == '健康险'}" @click="industryField1 = '健康险'">健康险</div>
          <div :class="{'active':industryField1 == '企业服务'}" @click="industryField1 = '企业服务'">企业服务</div>
          <div :class="{'active':industryField1 == '自媒体'}" @click="industryField1 = '自媒体'">自媒体</div>
          <div :class="{'active':industryField1 == '保险公司,保险科技,大数据,人工智能,SAAS,软件开发,车险,健康险,企业服务,自媒体'}" @click="industryField1 = '保险公司,保险科技,大数据,人工智能,SAAS,软件开发,车险,健康险,企业服务,自媒体'">其他领域</div>
        </div>
        <div class="anscontain"><span @click="industryField1 = ''">重置</span><span @click="checkCateIdIndustry">完成</span></div>
      </div>
    </van-popup>
    <!-- 烯牛特色 -->
    <van-popup v-model="showFeature" position="top">
      <div class="topPop">
        <div class="titleTab">
          <!-- <div :class="{'active':establish == ' '}" @click="establish = ' '">不限</div>
          <div :class="{'active':establish == '2013'}" @click="establish = '2013'">2013</div>
          <div :class="{'active':establish == '2014'}" @click="establish = '2014'">2014</div>
          <div :class="{'active':establish == '2015'}" @click="establish = '2015'">2015</div>
          <div :class="{'active':establish == '2016'}" @click="establish = '2016'">2016</div>
          <div :class="{'active':establish == '2017'}" @click="establish = '2017'">2017</div>
          <div :class="{'active':establish == '2018'}" @click="establish = '2018'">2018</div>
          <div :class="{'active':establish == '2019'}" @click="establish = '2019'">2019</div>
          <div :class="{'active':establish == '2020'}" @click="establish = '2020'">2020</div>
          <div :class="{'active':establish == '2021'}" @click="establish = '2021'">2021</div>
          <div :class="{'active':establish == '十年以上'}" @click="establish = '十年以上'">十年以上</div>
          <div :class="{'active':establish == '2013,2014,2015,2016,2017,2018,2019,2020,2021,十年以上'}" @click="establish = '2013,2014,2015,2016,2017,2018,2019,2020,2021,十年以上'">其他时间</div> -->

          <div :class="{'active':feature == '下载激增'}" @click="feature = '下载激增'">下载激增</div>
          <div :class="{'active':feature == '团队优秀'}" @click="feature = '团队优秀'">团队优秀</div>
          <div :class="{'active':feature == '媒体热议'}" @click="feature = '媒体热议'">媒体热议</div>
          <div :class="{'active':feature == '招聘活跃'}" @click="feature = '招聘活跃'">招聘活跃</div>
          <div :class="{'active':feature == '知名风投'}" @click="feature = '知名风投'">知名风投</div>
          <div :class="{'active':feature == '知名媒体报道'}" @click="feature = '知名媒体报道'">知名媒体报道</div>
          <div :class="{'active':feature == '有专利'}" @click="feature = '有专利'">有专利</div>
          <div :class="{'active':feature == '高新技术企业'}" @click="feature = '高新技术企业'">高新技术企业</div>
          <div :class="{'active':feature == '连续获投'}" @click="feature = '连续获投'">连续获投</div>
          <div :class="{'active':feature == '融资窗口期'}" @click="feature = '融资窗口期'">融资窗口期</div>
        </div>
        <div class="anscontain"><span @click="establish = ''">重置</span><span @click="checkCateId">完成</span></div>
      </div>
    </van-popup>

    <!-- 其他筛选 -->
    <van-popup v-model="showOther" position="top">
      <div class="sort">
        <div>
          <p :class="{'active':three == 1}" @click="three = 1">当前融资</p>
          <p :class="{'active':three == 2}" @click="three = 2">服务对象</p>
          <p :class="{'active':three == 3}" @click="three = 3">成立时间</p>
        </div>
        <div>
          <div v-if="three == 1">
            <p :class="{'active':!financing}"  @click="checkSort(' ')">不限</p>
            <!-- <p @click="checkSort(' ')">不需要融资</p>
            <p @click="checkSort(' ')">种子轮</p><p @click="checkSort(' ')">天使轮</p> -->
            <p :class="{'active':financing == 'A轮'}" @click="checkSort('A轮')">A轮</p>
            <p :class="{'active':financing == 'B轮'}" @click="checkSort('B轮')">B轮</p>
            <!-- <p @click="checkSort(' ')">C轮</p> -->
            <!-- <p @click="checkSort(' ')">D轮</p><p @click="checkSort(' ')">E轮</p>
            <p @click="checkSort(' ')">IPO</p><p @click="checkSort(' ')">上市</p> -->
            <p :class="{'active':financing == 'A轮,B轮'}" @click="checkSort('A轮,B轮')">其他轮次</p>
          </div>
          <div v-if="three == 2">
            <p :class="{'active':!serviceObject}" @click="checkSort(' ')">不限</p>
            <p :class="{'active':serviceObject == 'TO A'}" @click="checkSort('TO A')">TO A</p>
            <p :class="{'active':serviceObject == 'TO B'}" @click="checkSort('TO B')">TO B</p>
            <p :class="{'active':serviceObject == 'TO C'}" @click="checkSort('TO C')">TO C</p>
          </div>
          <div v-if="three == 3">
            <p :class="{'active':!establish}"  @click="checkSort(' ')">不限</p>
            <p :class="{'active':establish == '2013'}" @click="checkSort('2013')">2013</p>
            <p :class="{'active':establish == '2014'}" @click="checkSort('2014')">2014</p>
            <p :class="{'active':establish == '2015'}" @click="checkSort('2015')">2015</p>
            <p :class="{'active':establish == '2016'}" @click="checkSort('2016')">2016</p>
            <p :class="{'active':establish == '2017'}" @click="checkSort('2017')">2017</p>
            <p :class="{'active':establish == '2018'}" @click="checkSort('2018')">2018</p>
            <p :class="{'active':establish == '2019'}" @click="checkSort('2019')">2019</p>
            <p :class="{'active':establish == '2020'}" @click="checkSort('2020')">2020</p>
            <p :class="{'active':establish == '2021'}" @click="checkSort('2021')">2021</p>
            <p :class="{'active':establish == '十年以上'}" @click="checkSort('十年以上')">十年以上</p>
            <p :class="{'active':establish == '2013,2014,2015,2016,2017,2018,2019,2020,2021,十年以上'}" @click="checkSort('2013,2014,2015,2016,2017,2018,2019,2020,2021,十年以上')">其他时间</p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
// import { areaList } from '@vant/area-data';
export default {
  name: 'CompanyList',
   data(){
    return{
      three:1,
      showOther:false,//选择项
      showFeature:false,
      showindustryField:false,
      showArea:false,

      search:'',//筛选
      industryField:'', //领域
      industryField1:'',
      feature:'',//特色
      financing:'', //当前轮次
      serviceObject:'',//最新获投
      establish:'',//成立时间
      area:'',//地区
      area1:'',//地区


      // areaList 
      getDocList:[],//公司列表
      finished:false,
      loading:true,
      next_page_url:'',
      financing1:''
    }
  },
  mounted() {
    this.$refs.blackFix.style.top = this.$refs.posFix.offsetHeight + 'px'
    this.$refs.topMost.style.paddingTop = this.$refs.posFix.offsetHeight + this.$refs.blackFix.offsetHeight+'px'

    // 文件列表
    this.axios({
      method: 'POST',
      url: '/company/getCompanyList?search='+this.search+'&industryField='+this.industryField+'&feature='+this.feature+'&financing='+this.financing1+'&serviceObject='+this.serviceObject+'&establish='+this.establish+'&area='+this.area,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.loading = false
        this.getDocList = res.data.data.data;
        this.next_page_url = res.data.data.next_page_url
      }
    })
  },
  methods:{
    focusChange(){
      window.scrollTo(0,0)
      this.axios({
        method: 'POST',
        url: '/company/getCompanyList?search='+this.search+'&industryField='+this.industryField+'&feature='+this.feature+'&financing='+this.financing1+'&serviceObject='+this.serviceObject+'&establish='+this.establish+'&area='+this.area,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            // console.log(res.data.data)
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.data.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            // console.log('finish')
            this.finished = true
          }
        }
      })
    },
    
    closeAre(){
      setTimeout(()=>{this.area1 = this.area},1000)
    },
    closeInd(){
      setTimeout(()=>{this.industryField1 = this.industryField},1000)
    },
    changePath(path){
      this.path = path
      this.$router.push(path)
    },
    checkSort(a){
      this.showOther = false
      this.showFeature = false
      this.showindustryField = false
      this.showArea = false
      if(this.three == 1){
        this.financing = a
      }else if(this.three == 2){
        this.serviceObject = a
      }else if(this.three == 3){
        this.establish = a
      }

      this.getDocList = []
      this.firstLoad()
    },
    checkCateIdIndustry(){
      this.industryField = this.industryField1
      this.checkCateId()
    },
    checkCateIdArea(){
      this.area = this.area1
      this.checkCateId()
    },
    checkCateId(){
      this.showOther = false
      this.showFeature = false
      this.showindustryField = false
      this.showArea = false
      
      if(this.financing == 'A轮' || this.financing == 'B轮' || this.financing == 'A轮,B轮'){
        this.financing1 = this.financing
      }else{
        this.financing1 = ''
      }
      this.getDocList = []
      this.next_page_url = ''
      this.firstLoad()
    },
    firstLoad(){
      window.scrollTo(0,0)
      this.axios({
        method: 'POST',
        url: '/company/getCompanyList?search='+this.search+'&industryField='+this.industryField+'&feature='+this.feature+'&financing='+this.financing1+'&serviceObject='+this.serviceObject+'&establish='+this.establish+'&area='+this.area,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            // console.log(res.data.data)
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.data.next_page_url
            // console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        var url;
        url = this.next_page_url+'&search='+this.search+'&industryField='+this.industryField+'&feature='+this.feature+'&financing='+this.financing1+'&serviceObject='+this.serviceObject+'&establish='+this.establish+'&area='+this.area,
        this.axios({
          method: 'POST',
          url:url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    toDetail(id){
      this.$router.push('/CompanyDetail?id='+id)
    }
  }
}
</script>
<style lang="less" scoped>
#CompanyList{
  .sort{
    padding: 0.15rem 0.3rem 0.05rem;
    display: flex;
    >div:last-child{
      flex: 1;
    }
    >div{
      p{
        color: #989AB1;
        font-size: 0.12rem;
        margin-bottom: 0.12rem;
      }
      >p{
        padding-right: 0.3rem;
      }
    } 
    .active{
      color: #3890FF;
    }
  }
  .blackFix{
    z-index: 3;
    background: #16171D;
    height: 0.11rem;
    position: fixed;
    width: 100%;
  }
  .posFix{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: #16171D;
  }
  .allList{
    background: #23252E;
    padding-top: 1.5rem;
    .list:last-child{
      border-bottom: none;
    }
  }
  .list{
    display: flex;
    border-bottom: .1px solid #5C5D6E61;
    margin: 0 0.15rem;
    >img{
      width: 0.45rem;
      border-radius: 5px;
      height: 0.45rem;
      margin-top: 0.15rem;
    }
    >div{
      padding: 0.15rem;
      position: relative;
      flex: 1;
      .first{
        font-size: 0.15rem;
        color: #F6F7FD;
      }
      .third{
        display: flex;
        flex-wrap: wrap;
        >span{
          padding: 0 0.05rem;
          color: #75B2FF;
          font-size: 0.12rem;
          border-radius: 0.02rem;
          background: #2862AC;
          margin: 0 0.05rem 0 0;
          transform: scale(0.9);
        }
      }
      .forth{
        color: #727387;
        font-size: 0.13rem;
        margin-bottom: 0.06rem;
      }
      .second{
        margin: 0.06rem 0 0.06rem 0;
        color: #989AB1;
        display: flex;
        font-size: 0.14rem;
        >span{
          display: flex;
          margin-left: 0.1rem;
          color: #989AB1;
        }
      }
    }
  }

  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTab{
    padding: 0.15rem 0.1rem 0.15rem;
    display: flex;
    flex-wrap: wrap;
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
      margin-bottom: 0.1rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  .hotMain{
    background: #23252E;
    .forth{
      display: flex;
      padding:0 0.15rem 0.15rem;
        >div{
          display: flex;
          // justify-content: space-between;
          width: 100%;
          .active{
            color: #F6F7FD;
          }
          >span{
            color: #727387;
            display: flex;
            align-items: center;
            margin-right: 0.2rem;
            font-size: 0.12rem;
          }
        }
        
      }
  }
  .titleTop {
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    background: #23252E;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
      font-size: 0.14rem;
    }
    .active::after {
      position: absolute;
      content: "";
      height: 2px;
      background: #3890ff;
      left: 0;
      margin: auto 0.1rem;
      right: 0;
      bottom: -0.05rem;
    }
  }
}
</style>